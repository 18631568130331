import React from 'react';
import { ValidationRules, UseFormMethods } from 'react-hook-form';

interface Props extends Partial<Pick<UseFormMethods, 'register' | 'errors'>> {
  rules?: ValidationRules;
  qualifier: {
    name: string;
    label: string;
    values: string[];
  };
}

const QualifierRadio: React.FC<Props> = ({ qualifier, register }) => (
  <>
    <span className="input-label">{qualifier.label}</span>
    {qualifier.values.map((value, i) => (
      <div key={value}>
        <label className="check-label" htmlFor={qualifier.name}>
          <input
            ref={register({
              required: true,
            })}
            type="radio"
            name={qualifier.name}
            id={`${qualifier.name}_${i}`}
            value={value}
          />
          {value}
        </label>
      </div>
    ))}
  </>
);

export default QualifierRadio;
