import createStore from 'zustand';
import { devtools } from 'zustand/middleware';
import { IField, IOption } from '../types/types';

export type State = {
  fields: IField[];
  key: Record<string, unknown>;
  filtered: Record<string, IOption[]>;
  formStep: number;
  generateFields: (fields: IField[]) => void;
  nextStep: () => void;
  prevStep: () => void;
  setKey: (field: string, key: string) => void;
  setFiltered: (field: string | [], values?: IOption[]) => void;
};

const useUIStore = createStore<State>(
  devtools((set) => ({
    fields: [],
    key: {
      qualifier_1: 'Choose One',
    },
    filtered: {
      qualifier_2: [],
    },
    formStep: 1,
    generateFields: (fields) => set({ fields: [...fields] }),
    nextStep: () => set((state) => ({ formStep: state.formStep + 1 })),
    prevStep: () => set((state) => ({ formStep: state.formStep - 1 })),
    setKey: (field, key) =>
      set((state) => ({ key: { ...state.key, [field]: key } })),
    setFiltered: (field, values) =>
      set((state) => ({ filtered: { ...state.filtered, [field]: values } })),
  })),
);

export default useUIStore;
