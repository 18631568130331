import React from 'react';
import styled from 'styled-components';
import DuotoneFilter from '../../../utils/DuotoneFilter';
import BackgroundImage from 'gatsby-background-image';
import theme from './theme';

interface ExcerptProps {
  excerpt: string;
  image: string;
}

const Excerpt: React.FC<ExcerptProps> = ({ excerpt, image }) => (
  <>
    <ExcerptBackground Tag="section" fluid={image} backgroundColor={`#040e18`}>
      <DuotoneFilter id="red" r="0.47 0.71" g="0.14 0.43" b="0.13 0.43" />
      <Aside>{excerpt}</Aside>
    </ExcerptBackground>
  </>
);

export default Excerpt;

const ExcerptBackground = styled(BackgroundImage)`
  position: relative;
  :before,
  :after {
    filter: url('#red');
    background-attachment: fixed;
  }
  svg {
    position: absolute;
    height: 0;
  }
`;

const Aside = styled.aside`
  position: relative;
  color: white;
  text-align: left;
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 1.5rem;
  padding: 4rem 4rem;
  z-index: 10;
  max-width: ${theme.breakpoints.md};
  margin: 0 auto;
  @media screen and (min-width: ${theme.breakpoints.sm}) {
    font-size: 1.5rem;
    line-height: 1.625rem;
  }
`;
