import React from 'react';
import { ValidationRules, UseFormMethods } from 'react-hook-form';

interface Props extends Partial<Pick<UseFormMethods, 'register' | 'errors'>> {
  rules?: ValidationRules;
  qualifier: {
    name: string;
    label: string;
    values: string[];
  };
}

const QualifierInput: React.FC<Props> = ({ qualifier, register }) => (
  <>
    <span className="input-label">{qualifier.label}</span>
    <input
      type="text"
      name={qualifier.name}
      id={qualifier.name}
      ref={register({ required: true })}
      className={`form-text-input`}
    />
  </>
);

export default QualifierInput;
