import React from 'react';
import styled from 'styled-components';
import ColorOverlayFilter from '../../../utils/ColorOverlayFilter';
import DuotoneFilter from '../../../utils/DuotoneFilter';
import BackgroundImage from 'gatsby-background-image';
import Img, { FixedObject, GatsbyImageFixedProps } from 'gatsby-image';
import Form from './Form/Form';
import formatPhoneNumber from '../../../utils/formatPhoneNumber';
import theme from './theme';

interface PageHeaderProps {
  headline: string;
  subHeader: string;
  image: string;
  logo: FixedObject;
  phone: string;
  formUrl: string;
  formCtaOne: string;
  formCtaTwo: string;
  formCtaThree: string;
  callCta: string;
  formCompleteMsg: string;
  videoUrl: string;
  removePhone: boolean;
}

const PageHeader: React.FC<PageHeaderProps> = ({
  headline,
  subHeader,
  image,
  logo,
  phone,
  qualifyingFields,
  formURL,
  formCtaOne,
  formCtaTwo,
  formCtaThree,
  callCta,
  formCompleteMsg,
  videoURL,
  removePhone,
}) => {
  const shrinkHeadline = headline.length >= 60;

  return (
    <>
      <HeaderBackground Tag="section" fluid={image} backgroundColor={`#040e18`}>
        <Header>
          <DuotoneFilter
            id="dark_blue"
            r="0.04 0.2"
            g="0.11 0.26"
            b="0.15 0.31"
          />

          <TopBar>
            <div>
              <ColorOverlayFilter color="#ffffff" id="logo-color" />
              <Logo loading="eager" fixed={logo} alt="Logo" fadeIn={false} />
            </div>
            {!removePhone && (
              <a className="header-phone" href={`tel:${phone}`}>
                {formatPhoneNumber(phone)}
              </a>
            )}
          </TopBar>
          <HeaderContent shrinkHeadline={shrinkHeadline}>
            <div className="header-text">
              <h1>{headline}</h1>
              <SubHeader>{subHeader}</SubHeader>
            </div>
            {!removePhone && (
              <h2 className="header-cta">
                {callCta}
                <br />
                <a href={`tel:${phone}`}>{formatPhoneNumber(phone)}</a>
              </h2>
            )}

            <Form
              formCtaOne={formCtaOne}
              formCtaTwo={formCtaTwo}
              formCtaThree={formCtaThree}
              qualifyingFields={qualifyingFields}
              formURL={formURL}
              videoURL={videoURL}
              formCompleteMsg={formCompleteMsg}
            />
          </HeaderContent>
        </Header>
      </HeaderBackground>
    </>
  );
};

export default PageHeader;

//  Styled Component Types

interface HeaderProps {
  image: string;
}

interface HeaderContentProps {
  shrinkHeadline: boolean;
}

//   Styled Components

const HeaderBackground = styled(BackgroundImage)`
  :before,
  :after {
    filter: url('#dark_blue');
  }
`;

const Header = styled.div<HeaderProps>`
  box-sizing: border-box;
  background-attachment: fixed;
  z-index: 5;
  padding: 0 3rem 0px 3rem;
  :after {
  }
  :before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: block;
    background-image: url(${({ image }) => image});
    width: 100%;
    z-index: 10;
    filter: url('#dark_blue');
  }
  svg {
    position: absolute;
    height: 0;
  }
`;

const TopBar = styled.div`
  position: relative;
  height: 150px;
  left: 0;
  right: 0;
  display: grid;
  align-items: center;
  max-width: 960px;
  margin: 0 auto;
  z-index: 100;
  justify-items: center;
  padding-top: 1rem;
  margin-bottom: 1rem;
  @media screen and (min-width: 960px) {
    justify-items: left;
    padding-left: 3rem;
    grid-template-columns: 1fr 1fr;
  }
  svg {
    position: absolute;
    height: 0;
  }
  .header-phone {
    color: white;
    text-decoration: none;
    justify-self: center;
    font-size: 1.2em;
    font-weight: 300;
    margin: 1.5rem 0 0 0;
    @media screen and (min-width: ${theme.breakpoints.md}) {
      justify-self: right;
      margin: 0;
    }
  }
  @media screen and (min-width: ${theme.breakpoints.md}) {
    position: absolute;
  }
`;

const Logo = styled(Img)<GatsbyImageFixedProps>`
  filter: url(#logo-color);
`;

const HeaderContent = styled.div<HeaderContentProps>`
  display: grid;
  position: relative;
  align-content: start;
  max-width: 960px;
  margin: 0 auto;

  grid-template-areas:
    'brand'
    'phone'
    'content'
    'form'
    'cta';
  z-index: 100;
  @media screen and (min-width: 960px) {
    min-height: 80vh;
    padding: 150px 4rem 0px 4rem;
    grid-gap: 2em;
    grid-template-areas:
      'content    form'
      'cta      form';
  }
  ::before {
    height: 100%;
  }

  .header-text {
    max-width: 100%;
    grid-area: content;
    align-self: center;
    color: white;
    h1 {
      font-size: 1.25rem;
      @media screen and (min-width: 960px) {
        font-size: ${({ shrinkHeadline }) =>
          shrinkHeadline ? '1.75rem' : '2.25rem'};
      }
    }
  }

  .header-cta {
    text-align: center;
    grid-column: 1/2;
    grid-area: cta;
    color: white;
    a {
      text-decoration: none;
      color: white;
    }
    @media screen and (min-width: 960px) {
      text-align: left;
      font-size: 2em;
    }
  }
`;

const SubHeader = styled.p`
  display: none;
  color: white;
  @media screen and (min-width: ${theme.breakpoints.md}) {
    display: inline;
    font-size: 1.2em;
    line-height: 1.375em;
  }
`;
