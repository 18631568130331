import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import useUIStore from '../../../../state/useUIStore';
import useFormStore from '../../../../state/useFormStore';
import styled from 'styled-components';
import { getCookie } from '../../../../utils/getCookie';

interface FormData {
  [key: string]: string[] | string;
}

const ErrorPrompt = styled.span`
  color: #fcbc00;
  margin-bottom: 0.25rem;
`;

const StepTwo: React.FC = ({ formURL, formCtaTwo }) => {
  const {
    qualifierData,
    trackingData,
    userData,
    trustedFormData,
    updateUserData,
  } = useFormStore((state) => state);
  const { nextStep, prevStep } = useUIStore((state) => state);
  const { updateTrackingData } = useFormStore((state) => state);
  const [status, setFormStatus] = React.useState('');

  const { register, handleSubmit, errors, watch } = useForm<FormData>({
    defaultValues: {
      ...userData,
    },
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });

  const currentFormData = watch();

  const goBack = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    updateUserData(currentFormData);
    prevStep();
  };

  const flatten = (arr: string | string[]): string | null => {
    if (Array.isArray(arr)) {
      return arr.join(', ');
    }
    return null;
  };

  // console.log(trackingData.platform);

  const sendToFormKeep = (formData: FormData, formkeepURL: string) => {
    //  If any qualifiers are checklist, flatten to single string.
    Object.keys(formData).forEach((key) => {
      if (Array.isArray(formData[key])) {
        formData[key] = flatten(formData[key]);
      }
    });

    fetch(formkeepURL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
    })
      .then(() => {
        const fbEventAction = qualifierData.qualified
          ? 'Submission'
          : 'Complete Registration';
        const awEventAction = qualifierData.qualified
          ? trackingData.gaConversionLabels.gaQualifiedLeadConversionLabel
          : trackingData.gaConversionLabels.gaDisqualifiedLeadConversionLabel;
        const mainEventAction =
          trackingData.platform === 'Facebook'
            ? fbEventAction
            : trackingData.platform === 'Google Ads'
            ? awEventAction
            : null;
        // console.log(mainEventAction);
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: 'gaFormSubmit',
          gaEventCategory: 'Forms',
          gaEventSource: trackingData.platform,
          gaEventAction: mainEventAction,
          gaEventLabel: formData.caseType,
        });
        setFormStatus('SUCCESS');
        nextStep();
      })
      .catch(() => {
        setFormStatus('ERROR');
      });
  };
  useEffect(() => {
    updateTrackingData({
      fbc: getCookie('_fbc'),
      fbp: getCookie('_fbp'),
    });
  }, []);

  const onSubmit = (
    data: FormData,
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    e.preventDefault();

    updateUserData(currentFormData);
    const payload = {
      ...currentFormData,
      ...qualifierData,
      ...trackingData,
      ...trustedFormData,
    };
    sendToFormKeep(payload, formURL);
  };

  const emailValidation = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const phoneValidation = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;

  return (
    <>
      <span className="form-header">{formCtaTwo}</span>
      <form className="form-inner" onSubmit={handleSubmit(onSubmit)}>
        <label htmlFor="firstName" className="input-label">
          First Name*
        </label>
        {errors.firstName && (
          <ErrorPrompt>A first name is required</ErrorPrompt>
        )}
        <input
          type="text"
          name="firstName"
          id="firstName"
          ref={register({ required: true })}
          className={`form-text-input ${errors.firstName && 'field-error'}`}
        />
        <label htmlFor="lastName" className="input-label">
          Last Name*
        </label>
        {errors.lastName && <ErrorPrompt>A last name is required</ErrorPrompt>}
        <input
          type="text"
          name="lastName"
          id="lastName"
          ref={register({ required: true })}
          className={`form-text-input ${errors.lastName && 'field-error'}`}
        />
        <label htmlFor="email" className="input-label">
          Email
        </label>
        {errors.email && <ErrorPrompt>Email address is not valid</ErrorPrompt>}

        <input
          type="text"
          name="email"
          id="email"
          className={`form-text-input ${errors.email && 'field-error'}`}
          ref={register({ pattern: emailValidation })}
        />
        <label htmlFor="phone" className="input-label">
          Phone*
        </label>
        {errors.phone && (
          <ErrorPrompt>A valid phone number is required</ErrorPrompt>
        )}
        <input
          type="text"
          name="phone"
          id="phone"
          ref={register({ required: true, pattern: phoneValidation })}
          className={`form-text-input ${errors.phone && 'field-error'}`}
        />
        <label htmlFor="description" className="input-label">
          Case Info*
        </label>
        {errors.description && (
          <ErrorPrompt>Please tell us more about your case</ErrorPrompt>
        )}
        <textarea
          name="description"
          id="description"
          ref={register({ required: true })}
          className={`form-text-input ${errors.description && 'field-error'}`}
        />
        <div className="form-button-group">
          <button onClick={goBack} className="form-button" type="button">
            Back
          </button>
          <button className="form-button" type="submit">
            Submit
          </button>
        </div>
        {Object.keys(errors).length > 0 && (
          <span className="error-prompt light">
            Please complete all required fields.
          </span>
        )}
        {status === 'ERROR' && (
          <>
            <span className="error-prompt light">
              ERROR: There was a problem submitting your information. Please try
              resending the form. If the problem persists, please give us a
              call.
            </span>
          </>
        )}
      </form>
    </>
  );
};

export default StepTwo;
