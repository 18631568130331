import { useState, useEffect } from 'react';
import { IQualifier } from '../types/types';

interface IQualifierObject {
  [key: string]: string;
}

interface IRegexObject {
  [key: string]: RegExp;
}

export interface IFormData {
  [key: string]: string | string[];
}

function createRegexObject(
  arr: IQualifierObject[],
  flag?: string,
): IRegexObject {
  const initial: IRegexObject = {};
  arr.forEach((obj: IQualifierObject) => {
    Object.keys(obj).forEach((key: string) => {
      if (obj[key] !== null) initial[key] = new RegExp(obj[key], flag || 'i');
    });
  });
  return initial;
}

export const useFormQualification = (
  formData: IFormData,
  qualifiers: IQualifier[],
): boolean => {
  const [isQualified, setIsQualified] = useState(false);

  useEffect(() => {
    const standardQualifiers = qualifiers.filter(
      (qualifier) => qualifier._rawConditionalField.condition === 'standard',
    );

    const patterns = standardQualifiers.map((qualifier) => ({
      [qualifier._rawConditionalField.standard.name]:
        qualifier._rawConditionalField.standard.regex,
    }));

    const qualificationLogic = createRegexObject(patterns);

    const qual: Array<boolean> = [];

    //  Get the keys from formData
    Object.keys(formData).forEach((key) => {
      if (qualificationLogic[key] !== undefined) {
        const formItem = formData[key];
        if (typeof formItem !== 'string') {
          const re = qualificationLogic[key];
          const qualified = formItem.some((value: string) => re.test(value));
          qual.push(qualified);
        } else {
          const qItem = qualificationLogic[key];
          const qualified = qItem.test(formItem);
          qual.push(qualified);
        }
      }
    });

    if (qual.length < Object.keys(qualificationLogic).length) {
      setIsQualified(false);
      console.log('User is Not Qualified.');
    } else if (qual.every((q: boolean) => q === true)) {
      setIsQualified(true);
      console.log('User is Qualified.');
    } else {
      setIsQualified(false);
      console.log('User is Not Qualified.');
    }
  }, [formData]);

  return isQualified;
};
