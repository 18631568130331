import createStore from 'zustand';
import { devtools } from 'zustand/middleware';

export interface userData {
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  description: string;
}

export interface qualifierData {
  [key: string]: string | boolean;
}

type State = {
  qualifierData: qualifierData | Record<string, unknown>;
  userData: userData | Record<string, unknown>;
  trackingData: Record<string, unknown>;
  trustedFormData: Record<string, unknown>;
  updateQualifierData: (data: Record<string, unknown>) => void;
  updateUserData: (data: Record<string, unknown>) => void;
  updateTrackingData: (data: Record<string, unknown>) => void;
  updateTrustedFormData: (data: Record<string, unknown>) => void;
  setQualified: (data: boolean) => void;
};

const useFormStore = createStore<State>(
  devtools((set) => ({
    qualifierData: {},
    userData: {},
    trackingData: {},
    trustedFromData: {},
    updateQualifierData: (data) =>
      set((state) => ({ qualifierData: { ...state.qualifierData, ...data } })),
    updateUserData: (data) =>
      set((state) => ({ userData: { ...state.userData, ...data } })),
    updateTrackingData: (data) =>
      set((state) => ({ trackingData: { ...state.trackingData, ...data } })),
    setQualified: (qualified) =>
      set((state) => ({
        qualifierData: { ...state.qualifierData, qualified },
      })),
    setTrustedFormData: (tfFormData) =>
      set((state) => ({
        trustedFormData: { ...state.trustedFormData, tfFormData },
      })),
  })),
);

export default useFormStore;
