//  ARES TEMPLATE

//  BEGIN IMPORTING MODULES
//  Key Imports
import React, { useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import GlobalStyles from './components/GlobalStyles';
//  Layout Components
import Header from './components/Header';
import BlockSection from './components/BlockSection';
import Excerpt from './components/Excerpt';
import Featured from './components/Featured';
import Footer from './components/Footer';
//  State Management
import useFormStore from '../../state/useFormStore';
//  import useUIStore from '../../state/useUIStore';
//  SEO and Tracking
import Helmet from 'react-helmet';
import { detect } from 'detect-browser';
import { useLocation } from '@reach/router';
import * as queryString from 'query-string';
//  Types
import { SanityPageProps } from '../../types/types';
import { createConversionId } from '../../utils/createConversionId';
//  END OF MODULE IMPORTS

//  Template Component
const Template: React.FC<SanityPageProps> = ({ data }) => {
  //  Determine qualification fields setup in Sanity
  //  const generateFields = useUIStore((state) => state.generateFields);
  const { updateTrackingData } = useFormStore((state) => state);

  //  Page Specific Props
  const { content, campaign, title, removePhone } = data.sanityPage;

  //  Brand Specific Props
  const { brand, trackingInfo, platform } = campaign;

  //  Grab Conversion Labels
  const getCovnersionLabels = () => {
    const availableLabels = trackingInfo.gaConversionLabels.reduce(
      (obj, item) => Object.assign(obj, { [item.type]: item.labelId }),
      {},
    );
    return availableLabels;
  };

  //  Content Specific Props
  const {
    qualifyingFields,
    headline,
    subHeader,
    excerpt,
    _rawLawsuitInformation,
    _rawPotentialRisksAndInjuries,
    _rawCta,
    mainImage,
    secondaryImage,
    formCtaOneOverride,
    formCtaTwoOverride,
    formCtaThreeOverride,
    callCtaOverride,
    phoneOverride,
    videoURLOverride,
    _rawFormCompleteMsgOverride,
  } = content;

  const formCtaOne = formCtaOneOverride
    ? formCtaOneOverride
    : 'Start your free consultation.';
  const formCtaTwo = formCtaTwoOverride
    ? formCtaTwoOverride
    : 'We just need your information.';
  const formCtaThree = formCtaThreeOverride
    ? formCtaThreeOverride
    : 'We just need your information.';
  const callCta = callCtaOverride ? callCtaOverride : 'Talk with an Attorney';
  // const phoneNumber =
  const videoURL = videoURLOverride
    ? videoURLOverride
    : 'https://player.vimeo.com/video/431904469';

  //  Grab the case_type and other query params
  const location = useLocation();
  const { case_type, ...query } = queryString.parse(location.search);

  //  Once the page renders, we update our state and data layers with
  //  information about the user, url params, and the user's browser.
  useEffect(() => {
    //  generateFields(qualifyingFields.qualifiers);
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      adSource: platform.name,
      fbPixel: trackingInfo.trackingPixel,
      gaConversionID: trackingInfo.gaConversionID,
    });
    const browser = detect();

    const trackingData = {
      url: `${location.origin}${location.pathname}`,
      marketingId: campaign.marketingId,
      caseType: campaign.caseTypeOverride
        ? campaign.caseTypeOverride
        : campaign.caseTypeName.name
        ? campaign.caseTypeName.name
        : case_type
        ? case_type
        : '',
      platform: campaign.platform.name,
      vendor: campaign.vendor.name,
      ...query,
      browserName: browser === null ? '' : browser.name,
      browserVersion: browser === null ? '' : browser.version,
      browserType: browser === null ? '' : browser.type,
      os: browser === null ? '' : browser.os,
      gaConversionLabels: getCovnersionLabels(),
      conversionId: createConversionId(),
    };

    updateTrackingData(trackingData);
  }, []);
  /*eslint-disable */

  //  JSX markup for template
  return (
    <>
      <GlobalStyles />

      <Helmet
        htmlAttributes={{
          lang: 'en',
        }}
      >
        <title>{title}</title>
        <meta name="description" content={subHeader} />
      </Helmet>
      <div>
        <Header
          headline={headline}
          subHeader={subHeader}
          image={mainImage.asset.fluid}
          logo={brand.logo.asset.fixed}
          phone={phoneOverride ? phoneOverride : brand.phone}
          qualifyingFields={qualifyingFields}
          formURL={campaign.dataRouting}
          formCtaOne={formCtaOne}
          formCtaTwo={formCtaTwo}
          formCtaThree={formCtaThree}
          formCompleteMsg={_rawFormCompleteMsgOverride}
          callCta={callCta}
          videoURL={videoURL}
          removePhone={removePhone}
        />
      </div>
      <BlockSection id="lawsuit_information" content={_rawLawsuitInformation} />
      <Excerpt excerpt={excerpt} image={secondaryImage.asset.fluid} />
      <BlockSection
        id="lawsuit_information"
        content={_rawPotentialRisksAndInjuries}
      />
      <BlockSection id="lawsuit_information" content={_rawCta} />
      <Featured
        filterId="white"
        featured={brand.featured}
        logoColor="#fff"
        opacity={0.5}
      />
      <Footer brand={brand} />
    </>
  );
};

export default Template;

//  GraphQL Query
export const query = graphql`
  query($slug: String) {
    sanityPage(slug: { current: { eq: $slug } }) {
      title
      removePhone
      campaign {
        dataRouting
        marketingId
        caseTypeName {
          name
        }
        caseTypeOverride
        platform {
          name
        }
        vendor {
          name
        }
        trackingInfo {
          trackingPixel
          gaConversionID
          gaConversionLabels {
            type
            labelId
          }
        }
        brand {
          name
          legalName
          phone
          tos
          logo {
            asset {
              fixed(width: 200) {
                ...GatsbySanityImageFixed_noBase64
              }
            }
          }
          featured {
            _key
            asset {
              fluid(maxWidth: 150) {
                ...GatsbySanityImageFluid
              }
            }
          }
        }
      }

      content {
        qualifyingFields {
          qualifiers {
            _key
            _rawConditionalField
          }
        }
        headline
        subHeader
        excerpt
        _rawLawsuitInformation
        _rawPotentialRisksAndInjuries
        _rawCta
        formCtaOneOverride
        formCtaTwoOverride
        formCtaThreeOverride
        callCtaOverride
        phoneOverride
        _rawFormCompleteMsgOverride
        videoURLOverride
        mainImage {
          asset {
            fluid(maxWidth: 800) {
              ...GatsbySanityImageFluid_noBase64
            }
          }
        }
        secondaryImage {
          asset {
            fluid(maxWidth: 800) {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
    }
  }
`;
