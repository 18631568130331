import React from 'react';
import BlockContent, {
  BlockContentProps,
} from '@sanity/block-content-to-react';

const StepThree: React.FC = ({ formCtaThree, videoURL, formCompleteMsg }) => {
  return (
    <div>
      <span className="form-header">{formCtaThree}</span>
      <iframe
        title="What happens next?"
        className="confirmation-video"
        src={videoURL}
        width="640"
        height="260"
        frameBorder="0"
        allow="autoplay; fullscreen"
        allowFullScreen
      />
      {formCompleteMsg ? (
        <BlockContent blocks={formCompleteMsg} />
      ) : (
        <>
          <p className="confirmation-header">What happens next?</p>
          <ul className="confirmation-list">
            <li>
              A firm from our network will be reaching out to you shortly.{' '}
            </li>
            <li>
              Our goal is to help answer any questions you may have and verify
              your eligibility in regards to a claim.
            </li>
            <li> We look forward to talking to you soon!</li>
          </ul>
        </>
      )}
    </div>
  );
};
export default StepThree;
