import React, { useEffect } from 'react';
import { UseFormMethods } from 'react-hook-form';
import useUIStore from '../../../../state/useUIStore';

import { IFieldOption } from '../../../../types/types';

interface Props extends Partial<Pick<UseFormMethods, 'register' | 'errors'>> {
  fields: {
    id: string;
    key_label: string;
    key_name: string;
    label: string;
    name: string;
    fallback: string;
    options: IFieldOption[];
  };
}

const JsonField: React.FC<Props> = ({ fields, register }) => {
  //  const [key, setKey] = useState('Choose one');
  const keys = fields.options.map((option) => option.key);
  const initialValues = fields.options;

  const { filtered, setFiltered, key, setKey } = useUIStore((state) => state);

  const handleSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setKey(e.target.name, e.target.value);
  };

  useEffect(() => {
    const filteredValues = initialValues.filter(
      (value) => value.key === key[fields.key_name],
    );

    if (key === 'Choose one') {
      setFiltered([]);
    }
    if (filteredValues.length !== 0) {
      setFiltered('qualifier_2', filteredValues[0].values);
    }
  }, [key]);

  return (
    <>
      <span className="input-label">{fields.key_label}</span>
      <select
        name={fields.key_name}
        onChange={handleSelect}
        ref={register({
          required: true,
          validate: (value) => value !== 'Choose one',
        })}
        defaultValue="Choose one"
      >
        <option value="Choose one" disabled>
          Choose One
        </option>
        {keys.map((key, i) => (
          <option key={i} value={key}>
            {key}
          </option>
        ))}
      </select>
      <span className="input-label">{fields.label}</span>
      <select
        name={fields.name}
        ref={register({
          required: true,
          validate: (value) => value !== 'Choose one',
        })}
        defaultValue="Choose one"
      >
        <option value="Choose one" disabled>
          Choose one
        </option>

        {filtered[fields.name] !== undefined &&
          filtered[fields.name].map((option) => (
            <option key={`${option.id}`} value={option.value}>
              {option.value}
            </option>
          ))}
        {fields.fallback && (
          <option value={fields.fallback}>{fields.fallback}</option>
        )}
      </select>
    </>
  );
};

export default JsonField;
