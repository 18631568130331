import React from 'react';
import { useForm } from 'react-hook-form';
import {
  useFormQualification,
  IFormData,
} from '../../../../hooks/useQualificationLogic';
import QualifierField from './QualifierField';
import JsonField from './JsonField';
import useUIStore from '../../../../state/useUIStore';
import useFormStore from '../../../../state/useFormStore';

const StepOne: React.FC = ({ qualifyingFields, formCtaOne }) => {
  const { gaConversionLabels, platform } = useFormStore(
    (state) => state.trackingData,
  );
  const nextStep = useUIStore((state) => state.nextStep);
  const {
    updateQualifierData,
    setQualified,
    trackingData,
    setTrustedFormData,
  } = useFormStore((state) => state);
  const formState = useFormStore((state) => state.qualifierData);
  const { register, handleSubmit, errors, watch } = useForm<IFormData>({
    defaultValues: { ...formState },
  });

  const formData = watch();

  const isQualified = useFormQualification(
    formData,
    qualifyingFields.qualifiers,
  );

  const eventAction =
    platform === 'Facebook'
      ? 'View Content'
      : platform === 'Google Ads'
      ? gaConversionLabels.gaStepOneConversionLabel
      : null;

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    handleSubmit((data) => {
      const tfToken =
        document.getElementById('xxTrustedFormToken_0') !== null
          ? document.getElementById('xxTrustedFormToken_0').value
          : '';
      const tfCert =
        document.getElementById('xxTrustedFormCertUrl_0') !== null
          ? document.getElementById('xxTrustedFormCertUrl_0').value
          : '';
      const tfPing =
        document.getElementById('xxTrustedFormPingUrl_0') !== null
          ? document.getElementById('xxTrustedFormPingUrl_0').value
          : '';
      // console.log(eventAction);
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'gaFormSubmit',
        gaEventSource: platform,
        gaEventCategory: 'Forms',
        gaEventAction: eventAction,
        gaEventLabel: formData.caseType,
        conversionId: trackingData.conversionId,
      });
      updateQualifierData(data);
      setQualified(isQualified);
      setTrustedFormData({ tfToken, tfCert, tfPing });
      nextStep();
    })(e);
  };

  return (
    <>
      <span className="form-header">{formCtaOne}</span>
      <form className="form-inner" onSubmit={onSubmit}>
        <input name="xxTrustedFormCertUrl" />
        {qualifyingFields.qualifiers.map((field) => {
          if (field._rawConditionalField.condition === 'standard') {
            const fieldData = field._rawConditionalField.standard;
            return (
              <QualifierField
                key={field._key}
                qualifier={fieldData}
                register={register}
              />
            );
          }
          const fieldData = JSON.parse(
            field._rawConditionalField.json.data.code,
          );
          return (
            <JsonField
              key={field._key}
              fields={fieldData}
              register={register}
            />
          );
        })}
        <button className="form-button" type="submit">
          Get Help
        </button>
        {Object.keys(errors).length > 0 && (
          <span className="error-prompt light">
            Please complete all required fields.
          </span>
        )}
      </form>
    </>
  );
};

export default StepOne;
